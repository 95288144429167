import React, { useState, useEffect } from "react";
import "./User.css";
import $ from "jquery";
import { Switch, Route, NavLink } from "react-router-dom";
import firebase from "../../../../Services/firebase/firebase";
const db = firebase.firestore();
const User = () => {
  const [userList, setUserList] = useState(null);

  $("button").on("click", function () {
    $("button").removeClass("selected");
    $(this).addClass("selected");
  });

  useEffect(() => {
    console.log("perorderuseeffect");
    let list = [];
    db.collection("UsersList")
      .where("user_status", "==", "unblocked")
      .get()
      .then((data) => {
        // console.log("data", data);
        data.forEach((doc) => {
          list.push(doc.data());
        });
        setUserList(list);
      });

    console.log("list", list);
  }, []);

  const verifieduserHandler = () => {
    console.log("verifiedorder");
    let list = [];
    db.collection("UsersList")
      .where("user_status", "==", "unblocked")
      .get()
      .then((data) => {
        console.log("data", data);
        data.forEach((doc) => {
          list.push(doc.data());
        });
        setUserList(list);
        console.log("list", list);
      });
  };

  const blockedUser = () => {
    console.log("verifiedorder");
    let list = [];
    db.collection("UsersList")
      .where("user_status", "==", "blocked")
      .get()
      .then((data) => {
        console.log("data", data);
        data.forEach((doc) => {
          list.push(doc.data());
        });
        setUserList(list);
        console.log("list", list);
      });
  };

  const blockUser = (user) => {
    console.log("Accepted");
    console.log(">>>>>>", user);
    let value = window.confirm("Do you want block");
    if (value) {
      db.collection("UsersList")
        .doc(user.user_id)
        .update({
          user_status: "blocked"
        })
        .then(() => {
          let data = [...userList];

          let filterdata = data.filter((d) => d.user_id !== user.user_id);
          setUserList(filterdata);
        });
    } else {
      console.log("Not Accepted");
    }
  };
  const unblockUser = (user) => {
    console.log("Rejected");
    let value = window.confirm("Do you want unblock");
    if (value) {
      db.collection("UsersList")
        .doc(user.user_id)
        .update({
          user_status: "unblocked"
        })
        .then(() => {
          let data = [...userList];

          let filterdata = data.filter((d) => d.user_id !== user.user_id);
          setUserList(filterdata);
        });
    } else {
      console.log("nott rejected");
    }
  };
  let ui = null;
  if (userList === null) {
    ui = <p>No Users!!!</p>;
  } else {
    ui = userList.map((user) => {
      return (
        <>
          <div class="box list">
            <p class="tname">{user.user_username}</p>
            <p class="no">{user.user_phone_number}</p>
            <p class="spec">5</p>
            {/* <p class="add">
              <span>Block</span>
            </p> */}

            {user.user_status === "unblocked" ? (
              <button
                class="block"
                type="button"
                onClick={() => blockUser(user)}
              >
                Block
              </button>
            ) : (
              <button
                class="unblock"
                type="button"
                onClick={() => unblockUser(user)}
              >
                Unblock
              </button>
            )}
          </div>
        </>
      );
    });
  }
  return (
    <div class="user">
      <div className="rflex">
        {/* <NavLink to="" className="selected">
          All Users
        </NavLink>
      </div>
      <div className="toggle">
        <NavLink to="/home/delivery/reject" className="newreq row">
          Blocked Users
        </NavLink> */}
        <button
          type="button"
          className="selected"
          onClick={verifieduserHandler}
        >
          All Users
        </button>
        <button type="button" onClick={blockedUser}>
          Blocked Users
        </button>
      </div>
      <div className="userdetails">
        <div class="box head">
          <p class="tname">Name</p>
          <p class="no">Number</p>
          <p class="spec">Order History</p>
          <p class="add">Action</p>
        </div>
        {ui}
      </div>
    </div>
  );
};

export default User;
