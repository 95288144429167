// import firebase from "firebase/app";
// import "firebase/firestore";
// importScripts("https://www.gstatic.com/firebasejs/8.6.8/firebase-app.js");
// importScripts("https://www.gstatic.com/firebasejs/8.6.8/firebase-auth.js");
// importScripts("https://www.gstatic.com/firebasejs/8.6.8/firebase-firestore.js");
// import {tailorApp} from "./Services/firebase/firebase";

export default () => {
  self.addEventListener("message", (e) => {
    console.log("inside deleteAll", e.data);
    if (!e) return;
    let deletedItems = [...e.data.all];
    let items = {
      gender: e.data.gender,
      category: e.data.category,
      subcategory: e.data.subcategory,
      style: e.data.style,
      pattern: e.data.pattern
    };

    // let storageRef = tailorApp.storage();
    // console.log(tailorApp);
    const config = {
      apiKey: "AIzaSyAnLhD6nwpXJT_zrVpD0Dux5Zmac6DiTio",
      authDomain: "istitch-admin.firebaseapp.com",
      projectId: "istitch-admin",
      storageBucket: "istitch-admin.appspot.com",
      messagingSenderId: "29765024023",
      appId: "1:29765024023:web:5e27b4eca7822877c15f52",
      measurementId: "G-3DCJCKR5VY"
    };

    const tailorApp = firebase.initializeApp(config);
    const db = tailorApp.firestore();

    let listPromises = [];
    // items.pattern.forEach((p) => {
    //   if(p.type === "mainProduct") {
    //     listPromises.push(db.collection("gender")
    //   .doc(p.genderId)
    //   .collection("mainProduct")
    //   .doc("categories")
    //   .collection("category")
    //   .doc(p.categoryId)
    //   .collection("subcategory")
    //   .doc(p.subcategoryId)
    //   .collection("styles")
    //   .doc(p.styleId)
    //   .collection("patterns")
    //   .doc(p.patternId)
    //   .delete());
    //   } else {

    //   }
    // });

    // Promise.all(listPromises).then(() => {
    //   console.log("Successfully Deleted Paterrns");
    // }).catch(e => console.log(e));

    listPromises = [];
    let listPatterns = [];
    items.style.forEach((p) => {
      if (p.type === "mainProduct") {
        listPatterns.push(
          db
            .collection("gender")
            .doc(p.genderId)
            .collection("mainProduct")
            .doc("categories")
            .collection("category")
            .doc(p.categoryId)
            .collection("subcategory")
            .doc(p.subcategoryId)
            .collection("styles")
            .doc(p.styleId)
            .collection("patterns")
            .get()
        );
      }
      // db.collection("gender")
      //   .doc(p.genderId)
      //   .collection("mainProduct")
      //   .doc("categories")
      //   .collection("category")
      //   .doc(p.categoryId)
      //   .collection("subcategory")
      //   .doc(p.subcategoryId)
      //   .collection("styles")
      //   .doc(p.styleId)
      //   .delete()
      //   .then(() => {})
      //   .catch((e) => console.log(e));
    });
    Promise.all(listPatterns)
      .then((values) => {
        console.log("inside style...", values);
        // console.log("val", val);
        // values.forEach(val => {
        //   // let data = val.data();
        //   // list.push(data);
        // })
      })
      .catch((e) => console.log(e));

    // items.subcategory.forEach((p) => {
    //   db.collection("gender")
    //     .doc(p.genderId)
    //     .collection("mainProduct")
    //     .doc("categories")
    //     .collection("category")
    //     .doc(p.categoryId)
    //     .collection("subcategory")
    //     .doc(p.subcategoryId)
    //     .delete()
    //     .then(() => {
    //       storageRef
    //         .refFromURL(p.subcategoryImg)
    //         .delete()
    //         .then(() => {
    //           // console.log("image deleted successfullty, MyBin.js[284]");
    //           let filtered = deletedItems.filter(
    //             (delId) => p.subcategoryId !== delId.subcategoryId
    //           );
    //           deletedItems = [...filtered];
    //           // deletedItems.push(p);
    //           postMessage(filtered);
    //         });
    //     })
    //     .catch((e) => console.log(e));
    // });

    // items.category.forEach((p) => {
    //   db.collection("gender")
    //     .doc(p.genderId)
    //     .collection("mainProduct")
    //     .doc("categories")
    //     .collection("category")
    //     .doc(p.categoryId)
    //     .delete()
    //     .then(() => {
    //       storageRef
    //         .refFromURL(p.categoryImg)
    //         .delete()
    //         .then(() => {
    //           // console.log("image deleted successfullty, MyBin.js[284]");
    //           let filtered = deletedItems.filter(
    //             (delId) => p.categoryId !== delId.categoryId
    //           );
    //           deletedItems = [...filtered];
    //           // deletedItems.push(p);
    //           postMessage(filtered);
    //         });
    //     })
    //     .catch((e) => console.log(e));
    // });

    // items.gender.forEach((p) => {
    //   db.collection("gender")
    //     .doc(p.genderId)
    //     .delete()
    //     .then(() => {
    //       storageRef
    //         .refFromURL(p.genderImg)
    //         .delete()
    //         .then(() => {
    //           // console.log("image deleted successfullty,deleteAll.workers.js");
    //           let filtered = deletedItems.filter(
    //             (delId) => p.genderId !== delId.genderId
    //           );
    //           deletedItems = [...filtered];
    //           // deletedItems.push(p);
    //           postMessage(filtered);
    //         })
    //         .catch((e) => console.log(e));
    //     })
    //     .catch((e) => console.log(e));
    // });
    // delteItems in deletedItems collection
  });
};
