import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import "./BannerImage.css";
import firebase from "../../../../Services/firebase/firebase";

const db = firebase.firestore();
const BannerImage = () => {
  // const ref = useRef(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    db.collection("BannerImage")
      .doc("images")
      .get()
      .then((doc) => {
        let banner = doc.data();
        console.log("data", banner);
        setImage({
          image1: {
            img: null,
            imgUrl: banner.image1
          },
          image2: {
            img: null,
            imgUrl: banner.image2
          },
          image3: {
            img: null,
            imgUrl: banner.image3
          },
          image4: {
            img: null,
            imgUrl: banner.image4
          },
          show: banner.show
        });
        // setImage(banner);
      })
      .catch((e) => console.log(e));
  }, []);

  const getFile1 = () => {
    $("#uploadButton1").on("click", function () {
      $("#img").click();
    });

    $("#img").change(function () {
      var file = this.files[0];
      console.log(file);
      var reader = new FileReader();
      reader.onloadend = function () {
        $("#uploadButton1").css(
          "background-image",
          'url("' + reader.result + '")'
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
      }
    });
  };
  const getFile2 = () => {
    $("#uploadButton2").on("click", function () {
      $("#img2").click();
    });

    $("#img2").change(function () {
      var file = this.files[0];
      console.log(file);
      var reader = new FileReader();
      reader.onloadend = function () {
        $("#uploadButton2").css(
          "background-image",
          'url("' + reader.result + '")'
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
      }
    });
  };
  const getFile3 = () => {
    $("#uploadButton3").on("click", function () {
      $("#img3").click();
    });

    $("#img3").change(function () {
      var file = this.files[0];
      console.log(file);
      var reader = new FileReader();
      reader.onloadend = function () {
        $("#uploadButton3").css(
          "background-image",
          'url("' + reader.result + '")'
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
      }
    });
  };
  const getFile4 = () => {
    $("#uploadButton4").on("click", function () {
      $("#img4").click();
    });

    $("#img4").change(function () {
      var file = this.files[0];
      console.log(file);
      var reader = new FileReader();
      reader.onloadend = function () {
        $("#uploadButton4").css(
          "background-image",
          'url("' + reader.result + '")'
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
      }
    });
  };

  const onChangeHandler = (event) => {
    let name = event.target.name;
    let value = null;
    if (name === "first") {
      value = event.target.files[0];
      setImage((prevState) => {
        return {
          ...prevState,
          image1: {
            ...prevState.image1,
            img: value
          }
        };
      });
    } else if (name === "second") {
      value = event.target.files[0];
      setImage((prevState) => {
        return {
          ...prevState,
          image2: {
            ...prevState.image2,
            img: value
          }
        };
      });
    } else if (name === "third") {
      value = event.target.files[0];
      setImage((prevState) => {
        return {
          ...prevState,
          image3: {
            ...prevState.image3,
            img: value
          }
        };
      });
    } else if (name === "four") {
      value = event.target.files[0];
      setImage((prevState) => {
        return {
          ...prevState,
          image4: {
            ...prevState.image4,
            img: value
          }
        };
      });
    }
  };

  const updateImageHandler = (name) => {
    // ref.current.continuousStart();
    console.log("img", image, name);
    let img = null;
    let fieldname = "";
    if (name === "first") {
      img = image.image1.img;
      fieldname = "image1";
    } else if (name === "second") {
      img = image.image2.img;
      fieldname = "image2";
    } else if (name === "third") {
      img = image.image3.img;
      fieldname = "image3";
    } else if (name === "four") {
      img = image.image4.img;
      fieldname = "image4";
    }
    if (img === null) {
      alert("Choose any image to upload!!!");
    } else {
      let bucketName = "Images";
      let storageRef = firebase.storage().ref();
      let genderTimestamp = +new Date().getTime() + "-" + img.name;
      let imgRef = storageRef.child(`${bucketName}/${genderTimestamp}`);
      imgRef
        .put(img)
        .then((snapshot) => {
          imgRef.getDownloadURL().then((imgUrl) => {
            db.collection("BannerImage")
              .doc("images")
              .update({
                [fieldname]: imgUrl
              })
              .then(() => {
                console.log("Image Updated");
                if (name === "first") {
                  setImage((prevState) => {
                    return {
                      ...prevState,
                      image1: {
                        ...prevState.image1,
                        imgUrl: imgUrl
                      }
                    };
                  });
                } else if (name === "second") {
                  setImage((prevState) => {
                    return {
                      ...prevState,
                      image2: {
                        ...prevState.image2,
                        imgUrl: imgUrl
                      }
                    };
                  });
                } else if (name === "third") {
                  setImage((prevState) => {
                    return {
                      ...prevState,
                      image3: {
                        ...prevState.image3,
                        imgUrl: imgUrl
                      }
                    };
                  });
                } else if (name === "four") {
                  setImage((prevState) => {
                    return {
                      ...prevState,
                      image4: {
                        ...prevState.image4,
                        imgUrl: imgUrl
                      }
                    };
                  });
                }
              });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const hideHandler = (e) => {
    if (e.target.checked) {
      // true - show or hide(false)
      db.collection("BannerImage").doc("images").update({
        show: false
      });
    } else {
      // false - hide(true)
      db.collection("BannerImage").doc("images").update({
        show: true
      });
    }
  };

  return (
    <div class="banner">
      {/* <div class="can-toggle">
        <input
          id="toggle"
          name="toggle"
          type="checkbox"
          // checked={image.hide}
          data-toggle="toggle"
          onChange={hideHandler}
        />
        <label for="toggle">
          <div
            class="can-toggle__switch"
            data-checked="Hide"
            data-unchecked="Show"
          ></div>
        </label>
      </div> */}
      <div class="row m-0">
        <div class="col-md-7 p-0">
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="3"
              ></li>
            </ol>

            <div class="carousel-inner">
              {image !== null && image.show && (
                <>
                  {console.log(image.image1)}
                  <div class="carousel-item active">
                    <img
                      src={image.image1.imgUrl}
                      // src="/images/1.jpg"
                      class="d-block w-100"
                      alt="img1"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src={image.image2.imgUrl}
                      class="d-block w-100"
                      alt="img2"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src={image.image3.imgUrl}
                      class="d-block w-100"
                      alt="img3"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src={image.image4.imgUrl}
                      class="d-block w-100"
                      alt="img"
                    />
                  </div>
                </>
              )}
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div class="col-md-5 right-col p-0">
          <div class="upload-banner row">
            {image !== null && (
              <>
                <div class="col-6">
                  <div class="upload-img">
                    <input
                      type="file"
                      id="img"
                      name="first"
                      accept=".gif, .jpg, .png"
                      onChange={onChangeHandler}
                    />
                    <label
                      onClick={() => {
                        getFile1();
                        // setImgView(true);
                      }}
                      htmlFor="img"
                      id="uploadButton1"
                      style={{
                        backgroundImage: `url('${image.image1.imgUrl}')`
                      }}
                    >
                      <span>+</span>
                    </label>
                  </div>
                  <button
                    type="button"
                    class="bt"
                    onClick={() => updateImageHandler("first")}
                  >
                    Update
                  </button>
                </div>

                <div class="col-6">
                  <div class="upload-img">
                    <input
                      type="file"
                      id="img2"
                      name="second"
                      accept=".gif, .jpg, .png"
                      onChange={onChangeHandler}
                    />
                    <label
                      onClick={() => {
                        getFile2();
                        // setImgView(true);
                      }}
                      htmlFor="img2"
                      id="uploadButton2"
                      style={{
                        backgroundImage: `url('${image.image2.imgUrl}')`
                      }}
                    >
                      <span>+</span>
                    </label>
                  </div>
                  <button
                    type="button"
                    class="bt"
                    onClick={() => updateImageHandler("second")}
                  >
                    Update
                  </button>
                </div>

                <div class="col-6">
                  <div class="upload-img">
                    <input
                      type="file"
                      id="img3"
                      name="third"
                      accept=".gif, .jpg, .png"
                      onChange={onChangeHandler}
                    />
                    <label
                      onClick={() => {
                        getFile3();
                        // setImgView(true);
                      }}
                      htmlFor="img3"
                      id="uploadButton3"
                      style={{
                        backgroundImage: `url('${image.image3.imgUrl}')`
                      }}
                    >
                      <span>+</span>
                    </label>
                  </div>
                  <button
                    type="button"
                    class="bt"
                    onClick={() => updateImageHandler("third")}
                  >
                    Update
                  </button>
                </div>
                <div class="col-6">
                  <div class="upload-img">
                    <input
                      type="file"
                      id="img4"
                      name="four"
                      accept=".gif, .jpg, .png"
                      onChange={onChangeHandler}
                    />
                    <label
                      onClick={() => {
                        getFile4();
                        // setImgView(true);
                      }}
                      htmlFor="img4"
                      id="uploadButton4"
                      style={{
                        backgroundImage: `url('${image.image4.imgUrl}')`
                      }}
                    >
                      <span>+</span>
                    </label>
                  </div>
                  <button
                    type="button"
                    class="bt"
                    onClick={() => updateImageHandler("four")}
                  >
                    Update
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BannerImage;
