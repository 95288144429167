import React from "react";
import ReactDOM from "react-dom";
import Card from "../Card/Card";

import classes from "./ChangeModal.module.css";
import "./ChangeModal.css";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  let change = null;

  change = (
    <>
      <p>deleting all items</p>
    </>
  );

  return (
    <Card className={classes.modal}>
      <div className="addnew">
        <h2 className>Change {props.title}</h2>
        <form name="form">
          <div>
            {change}
            <button
              type="button"
              className="draft"
              onClick={() => props.submit()}
            >
              ok
            </button>
            <button
              type="button"
              className="publish"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Card>
  );
};

const DeleteAllModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.closeModal} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          closeModal={props.closeModal}
          // onChange={props.onChange}
          submit={props.submit}
        />,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

export default DeleteAllModal;
